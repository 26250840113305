// "use client";
// import React, { useEffect, useRef } from "react";
//
// const RunningTrackCarousel = ({ data, planet }) => {
//   const trackRef = useRef(null);
//   const aboutCarouselTexts = [
//     { id: 0, text: data.mission_carousel_narural },
//     { id: 1, text: data.mission_carousel_sugar },
//     { id: 2, text: data.mission_carousel_aditive },
//     { id: 3, text: data.mission_carousel_gmo },
//     { id: 4, text: data.mission_carousel_lactose },
//   ];
//   const materialsCarouselTexts = [
//     { id: 0, text: data.planet_carousel_text_glass },
//     { id: 1, text: data.planet_carousel_text_alu },
//     { id: 2, text: data.planet_carousel_text_paper },
//     { id: 3, text: data.planet_carousel_text_box },
//   ];
//   const aboutCarouselData = Object.entries(data?.mission_carousel[0]).map(
//     ([key, value], idx) => ({
//       id: idx,
//       image: value,
//       text: aboutCarouselTexts[idx].text,
//     }),
//   );
//   const materialCarouselData = Object.entries(data?.planer_carousel[0]).map(
//     ([key, value], idx) => ({
//       id: idx,
//       image: value,
//       text: materialsCarouselTexts[idx].text,
//     }),
//   );
//
//   useEffect(() => {
//     const track = trackRef.current;
//     let animation;
//
//     if (track) {
//       const animate = () => {
//         track.scrollLeft += 1; // Increment scroll position
//         if (track.scrollLeft >= track.scrollWidth / 2) {
//           track.scrollLeft = 0; // Reset to start for infinite effect
//         }
//         animation = requestAnimationFrame(animate);
//       };
//
//       animation = requestAnimationFrame(animate);
//
//       return () => cancelAnimationFrame(animation); // Cleanup on unmount
//     }
//   }, []);
//
//   const dataToRun = planet
//     ? [...materialCarouselData, ...materialCarouselData]
//     : [...aboutCarouselData, ...aboutCarouselData, ...aboutCarouselData];
//
//   return (
//     <div className="relative w-full h-[60px] overflow-hidden">
//       {/* Track container */}
//       <div
//         ref={trackRef}
//         className="flex items-center space-x-2 h-full animate-scroll gap-4 overflow-hidden"
//         style={{ scrollBehavior: "smooth" }}
//       >
//         {dataToRun.map((item, index) => (
//           <div
//             key={index}
//             className={`flex-shrink-0 flex items-center justify-center  bg-[#F7F4ED]`}
//           >
//             <div className={"flex items-center"}>
//               <div className={"pr-[10px]"}>
//                 <img
//                   src={item.image}
//                   alt={`mission-carousel-${index}`}
//                   className={"w-[20px] h-[20px]"}
//                   style={{
//                     width: "20px",
//                     height: "21.651px",
//                   }}
//                 />
//               </div>
//               <div
//                 className={
//                   "border border-t-0 border-b-0 border-l-0 border-r-[#8F7E76] h-[10px]"
//                 }
//               ></div>
//               <p className="uppercase font-montserrat font-normal text-[14px] leading-[32px] tracking-[2.8px] pl-[10px] text-[#8F7E76] ">
//                 {item.text}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
//
// export default RunningTrackCarousel;
"use client";
import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RunningTrackCarousel = ({ data, planet, isMobile }) => {
  const trackRef = useRef(null);

  const aboutCarouselTexts = [
    { id: 0, text: data.mission_carousel_narural },
    { id: 1, text: data.mission_carousel_sugar },
    { id: 2, text: data.mission_carousel_aditive },
    { id: 3, text: data.mission_carousel_gmo },
    { id: 4, text: data.mission_carousel_lactose },
  ];
  const materialsCarouselTexts = [
    { id: 0, text: data.planet_carousel_text_glass },
    { id: 1, text: data.planet_carousel_text_alu },
    { id: 2, text: data.planet_carousel_text_paper },
    { id: 3, text: data.planet_carousel_text_box },
  ];
  const aboutCarouselData = Object.entries(data?.mission_carousel[0]).map(
    ([key, value], idx) => ({
      id: idx,
      image: value,
      text: aboutCarouselTexts[idx].text,
    }),
  );
  const materialCarouselData = Object.entries(data?.planer_carousel[0]).map(
    ([key, value], idx) => ({
      id: idx,
      image: value,
      text: materialsCarouselTexts[idx].text,
    }),
  );

  const dataToRun = planet
    ? [...materialCarouselData]
    : [
        ...aboutCarouselData,
        ...aboutCarouselData,
        ...aboutCarouselData,
        ...aboutCarouselData,
      ];

  useEffect(() => {
    const track = trackRef.current;
    let animation;

    if (track) {
      const animate = () => {
        track.scrollLeft += 1; // Increment scroll position
        if (track.scrollLeft >= track.scrollWidth / 2) {
          track.scrollLeft = 0; // Reset to start for infinite effect
        }
        animation = requestAnimationFrame(animate);
      };

      animation = requestAnimationFrame(animate);

      return () => cancelAnimationFrame(animation); // Cleanup on unmount
    }
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: 1, // Adjust to your needs
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
  };

  return (
    <>
      {isMobile ? (
        <div className="relative w-full h-[60px]">
          <Slider {...settings}>
            {dataToRun.map((item, index) => (
              <div key={index} className="flex items-center justify-center">
                <div className="flex items-center bg-[#F7F4ED] justify-center py-2">
                  <img
                    src={item.image}
                    alt={`carousel-item-${index}`}
                    className="w-[20px] h-[20px] mr-2"
                  />
                  <div
                    className={
                      "border border-t-0 border-b-0 border-l-0 border-r-[#8F7E76] h-[10px]"
                    }
                  />
                  <p className="uppercase font-montserrat font-normal text-[14px] leading-[32px] tracking-[2.8px] text-[#8F7E76] pl-[10px]">
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : null}
      {!isMobile && !planet ? (
        <div className="relative w-full h-[60px] overflow-hidden">
          <div
            ref={trackRef}
            className="flex items-center space-x-2 h-full animate-scroll gap-4 overflow-hidden"
            style={{ scrollBehavior: "smooth" }}
          >
            {dataToRun.map((item, index) => (
              <div
                key={index}
                className={`flex-shrink-0 flex items-center justify-center  bg-[#F7F4ED]`}
              >
                <div className={"flex items-center"}>
                  <div className={"pr-[10px]"}>
                    <img
                      src={item.image}
                      alt={`mission-carousel-${index}`}
                      className={"w-[20px] h-[20px]"}
                      style={{
                        width: "20px",
                        height: "21.651px",
                      }}
                    />
                  </div>
                  <div
                    className={
                      "border border-t-0 border-b-0 border-l-0 border-r-[#8F7E76] h-[10px]"
                    }
                  ></div>
                  <p className="uppercase font-montserrat font-normal text-[14px] leading-[32px] tracking-[2.8px] pl-[10px] text-[#8F7E76] ">
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RunningTrackCarousel;
