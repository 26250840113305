const PlayVideoButton = () => {
  return (
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="29.665"
        cy="29.7193"
        rx="29.0322"
        ry="29.0669"
        fill="#F7F4ED"
      />
      <path
        d="M41.7151 30.5849C42.3812 30.1999 42.3812 29.2384 41.7151 28.8534L24.3908 18.8392C23.7241 18.4538 22.8903 18.9349 22.8903 19.705V39.7333C22.8903 40.5033 23.7241 40.9844 24.3908 40.5991L41.7151 30.5849Z"
        fill="#7C764C"
      />
    </svg>
  );
};

export default PlayVideoButton;
