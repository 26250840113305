"use client";

import React, { useEffect, useState } from "react";
import PlayVideoButton from "@/app/components/icons/PlayVideoButton";
import SwipeRightIcon from "@/app/components/icons/SwipeRightIcon";
import SwipeLeftIcon from "@/app/components/icons/SwipeLeftIcon";
import { useSwipeable } from "react-swipeable";

const VideoCarouselCustom = ({ urls, isMobile }) => {
  const [currentSlide, setCurrentSlide] = useState(Math.floor(urls.length / 2));
  // const [isPlaying, setIsPlaying] = useState(false);
  // const [videoActive, setVideoActive] = useState(false);
  // const [isVisible, setIsVisible] = useState(true);
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={`p-[20px] pr-[10px] md:p-[50px]`} onClick={onClick}>
        <SwipeRightIcon />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={`p-[20px] pl-[10px] md:p-[50px]`} onClick={onClick}>
        <SwipeLeftIcon />
      </div>
    );
  };

  const handleCarouselNavigation = (type) => {
    setCurrentSlide((current) => {
      if (type === "prev") return current > 0 ? current - 1 : 0;
      if (type === "next")
        return current < urls.length - 1 ? current + 1 : urls.length - 1;
      return current;
    });
    const videos = document.querySelectorAll("video");
    videos.forEach((video, index) => {
      if (index !== currentSlide && !video.paused) {
        video.pause();
      }
    });
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleCarouselNavigation("next"),
    onSwipedRight: () => handleCarouselNavigation("prev"),
    preventScrollOnSwipe: true,
    trackMouse: true, // Optional: Allow mouse swiping for desktop
  });
  // const togglePlay = async (id) => {
  //   let videoElem = document.getElementById(`video-${id}`);
  //   if (videoElem && id === currentSlide) {
  //     if (isPlaying) {
  //       try {
  //         await videoElem.pause();
  //         setVideoActive(false);
  //       } catch (error) {
  //         console.error("Playback failed:", error);
  //       }
  //     } else {
  //       try {
  //         await videoElem.play();
  //         setVideoActive(true);
  //       } catch (error) {
  //         console.error("Playback failed:", error);
  //       }
  //     }
  //     setIsPlaying(!isPlaying);
  //   }
  // };
  const handleMouseEnter = (idx) => {
    setCurrentSlide(idx);
  };
  //
  // const handleMouseLeave = () => {
  //   setIsVisible(false);
  // };
  const mobileStyles = (idx) => {
    if (isMobile) {
      return idx === currentSlide ? "flex px-[40px]" : "hidden";
    }
    return "flex";
  };

  useEffect(() => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video, index) => {
      if (index !== currentSlide && !video.paused) {
        video.pause();
      }
    });
  }, [currentSlide]);

  return (
    <div
      {...swipeHandlers}
      className="flex items-center justify-center md:-mt-[250px] lg:-mt-[250px] bg-cruxBeige"
    >
      <PrevArrow onClick={() => handleCarouselNavigation("prev")} />
      {urls.map((url, idx) => (
        <div
          key={`video-carousel-item-${idx}`}
          className={`
                          ${mobileStyles(idx)} items-center justify-center transition-transform duration-1000 ease-in-out
                          ${idx === currentSlide ? "scale-125 relative z-[20]" : "scale-100"}
                        `}
          onMouseEnter={() => handleMouseEnter(idx)}
          // onMouseLeave={handleMouseLeave}
        >
          <video
            id={`video-${idx}`}
            className={`${idx !== currentSlide ? "grayscale" : "rounded-[30px] shadow-lg "}`}
            playsInline
            width="305"
            height="450"
            onError={(e) => console.error("Error loading video:", e)}
            autoPlay={false}
            controls={idx === currentSlide}
            muted={true}
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/*<div*/}
          {/*  className={`*/}
          {/*    absolute z-10 left-1/4 top-[50%] md:left-1/2 md:top-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 2xl:left-[35%] hover:scale-110 transition-transform duration-300 ease-in-out */}
          {/*    ${isVisible && !videoActive && idx === currentSlide ? "opacity-100" : "opacity-0"}*/}
          {/*  `}*/}
          {/*  onClick={() => togglePlay(idx)}*/}
          {/*>*/}
          {/*  <PlayVideoButton />*/}
          {/*</div>*/}
        </div>
      ))}
      <NextArrow onClick={() => handleCarouselNavigation("next")} />
    </div>
  );
};

export default VideoCarouselCustom;
