"use client";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SwipeLeftIcon from "@/app/components/icons/SwipeLeftIcon";
import SwipeRightIcon from "@/app/components/icons/SwipeRightIcon";
import "@/app/styles/global.scss";
import SwipeLeftIconMobile from "@/app/components/icons/SwipeLeftIconMobile";
import SwipeRightIconMobile from "@/app/components/icons/SwipeRightIconMobile";

const TestimonialCarousel = ({ data, isMobile }) => {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow z-10`}
        style={{ ...style, display: "block", right: "15%" }}
        onClick={onClick}
      >
        <SwipeRightIcon />
      </div>
    );
  };

  const NextArrowMobile = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow z-10`}
        style={{ ...style, display: "block", right: "3%", top: "42%" }}
        onClick={onClick}
      >
        <SwipeRightIconMobile />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow z-10`}
        style={{ ...style, display: "block", left: "15%" }}
        onClick={onClick}
      >
        <SwipeLeftIcon />
      </div>
    );
  };

  const PrevArrowMobile = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow z-10`}
        style={{ ...style, display: "block", left: "3%", top: "42%" }}
        onClick={onClick}
      >
        <SwipeLeftIconMobile />
      </div>
    );
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 5,
    slidesToScroll: 1,
    centerMode: true,
    beforeChange: (_, newIndex) => setCurrentSlide(newIndex),
    focusOnSelect: true,
    centerPadding: isMobile ? "10px" : "0px",
    nextArrow: isMobile ? <NextArrowMobile /> : <NextArrow />,
    prevArrow: isMobile ? <PrevArrowMobile /> : <PrevArrow />,
  };
  const carouselItems = Object.entries(data?.testimonial_carousel[0]).map(
    ([key, value]) => ({
      image: value,
    }),
  );
  return (
    <>
      <div className="flex flex-col pb-[44px]">
        <div className="flex flex-row mx-auto w-full xsm:py-0">
          <Slider {...settings} className="w-full md:pt-[20px]">
            {carouselItems?.map((item, index) => (
              <div
                key={index}
                className={`ml-[25%] md:ml-[95%] rounded-[30px] pt-[10px] pb-[60px] md:py-[60px] transition-transform duration-1000 ease-in-out ${index === currentSlide ? "scale-125 relative z-[20]" : "scale-90"} ${index !== currentSlide ? "filter blur-sm" : ""}`}
              >
                <div
                  style={{
                    backgroundImage: `url(${item.image})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // width: isMobile && '70vw',
                    width: isMobile ? "60%" : "85%",
                    height: isMobile ? "235px" : "250px",
                  }}
                ></div>
                <p className="!text-left !pl-[30px] !md:pl-0 text-[12px] italic mt-[-30px] xl:mt-[-35px] leading-[42px] font-normal font-inter text-[#777169] md:text-center md:text-[14px] 2xl:text-[16px]">
                  izvor: instagram direct message
                </p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {!isMobile ? (
        <img
          src={data.testimonial_carousel_image_desktop}
          alt={"testimonial-carousel-image-desktop"}
          className={"w-[100%] -mt-[250px]"}
        />
      ) : null}
    </>
  );
};
export default TestimonialCarousel;
