import { memo } from "react";

const SwipeLeftIcon = () => {
  return (
    <svg
      width="19"
      height="34"
      viewBox="0 0 29 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_818_383" fill="white">
        <path d="M29 3.45822e-07L3.21972e-07 27L29 54" />
      </mask>
      <path
        d="M32.4071 3.65947C34.4282 1.77778 34.5412 -1.38602 32.6595 -3.40709C30.7778 -5.42816 27.614 -5.54116 25.5929 -3.65947L32.4071 3.65947ZM3.21972e-07 27L-3.40709 23.3405C-4.423 24.2864 -5 25.612 -5 27C-5 28.388 -4.423 29.7136 -3.40709 30.6595L3.21972e-07 27ZM25.5929 57.6595C27.614 59.5412 30.7778 59.4282 32.6595 57.4071C34.5412 55.386 34.4282 52.2222 32.4071 50.3405L25.5929 57.6595ZM25.5929 -3.65947L-3.40709 23.3405L3.40709 30.6595L32.4071 3.65947L25.5929 -3.65947ZM-3.40709 30.6595L25.5929 57.6595L32.4071 50.3405L3.40709 23.3405L-3.40709 30.6595Z"
        fill="#5B4841"
        mask="url(#path-1-inside-1_818_383)"
      />
    </svg>
  );
};
export default memo(SwipeLeftIcon);
