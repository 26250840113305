import { memo } from "react";

const SwipeRightIcon = () => {
  return (
    <svg
      width="19"
      height="34"
      viewBox="0 0 29 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_818_382" fill="white">
        <path d="M-6.3188e-07 54L29 27L1.72853e-06 -1.26763e-06" />
      </mask>
      <path
        d="M-3.40709 50.3405C-5.42816 52.2222 -5.54116 55.386 -3.65947 57.4071C-1.77779 59.4282 1.38602 59.5412 3.40709 57.6595L-3.40709 50.3405ZM29 27L32.4071 30.6595C33.423 29.7136 34 28.388 34 27C34 25.612 33.423 24.2864 32.4071 23.3405L29 27ZM3.40709 -3.65947C1.38603 -5.54116 -1.77778 -5.42816 -3.65947 -3.40709C-5.54115 -1.38602 -5.42816 1.77778 -3.40709 3.65947L3.40709 -3.65947ZM3.40709 57.6595L32.4071 30.6595L25.5929 23.3405L-3.40709 50.3405L3.40709 57.6595ZM32.4071 23.3405L3.40709 -3.65947L-3.40709 3.65947L25.5929 30.6595L32.4071 23.3405Z"
        fill="#5B4841"
        mask="url(#path-1-inside-1_818_382)"
      />
    </svg>
  );
};
export default memo(SwipeRightIcon);
